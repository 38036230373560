.kiks_subtitle {
    background-color: #1565c0;
    color:white;
    font-size: large;
    font-weight: 600;
    padding: 8px 10px;
    margin-left: -10px;
    margin-bottom: 1.5rem;
    display: inline-block;
}

.icon-max {
    max-height: 100px;
    max-width: 40%;
}

.bolder{
    font-weight: 600;
}

.kiks-research{
    font-size: 0.875rem;
    margin-bottom: 48px;
}

.highlightbox_btn {
    margin-left: 20px;
    color: #1565c0;
    border: 1px solid #1565c0;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 8px;
}